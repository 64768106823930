import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import { createUser, verifyUser, resendCode, logout } from '../../modules/Users/UsersActions';
import signUpbackgroundImage from '../../assets/images/signup-background.svg';
import signUpBackgroundStars from '../../assets/images/signup-background-stars.svg';
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";
import OTPForm from "./OTPForm";
import InfoForm from "./InfoForm";
import Consts from "../../Consts";
import { useDispatch } from "react-redux";
import ModalDialog from "../Dialog/Dialog";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import { useLocation } from "react-router-dom";
import HelmetProvider from "../Helmet/Helmet";

function otpVerification(data, history, postSignUpProcess) {
    return verifyUser(data).then((response) => {
        if (response && response?.data?.success) {
            postSignUpProcess();
        } else {
            throw {
                msg: 'Entered OTP is wrong'
            };
        }
    });
}

const infoConfig = {
    heading: "Sign Up",
    subHeading: "Hey! Enter your details to create your account.",
    fullname: true,
    phoneNumber: true,
    schoolName: true
}


export function SignupForm({ postSignUpProcess, goToLogin }) {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState(null);
    const [isEmailValid, setEmailValid] = useState(true);
    const [otpError, setOTPError] = useState('');

    return (
        <div className={classes.leftMainBox}>
            <div className={classes.logoImage}>
                <img src={logoImage} className={classes.bulbImage} alt="Logo" />
                <img src={logoWithTagline} className={classes.tagline} alt="Tag line" />
            </div>
            <div className={classes.mainBox}>
                {!userInfo && <InfoForm isEmailValid={isEmailValid}
                    goToLogin={goToLogin}
                    onSubmit={e => {
                        createUser(e).then(d => {
                            if (d.success) {
                                setUserInfo(e);
                            } else if (d.errorCode === "already_exist") {
                                setEmailValid(false);
                            }
                        });
                    }}
                    config={infoConfig}
                />}
                {userInfo && <OTPForm
                    onCreate={e =>
                        otpVerification({
                            ...e,
                            ...userInfo
                        }, history, postSignUpProcess).then(e => setOTPError(''))
                            .catch(err => setOTPError(err?.msg || 'Unknown'))
                    }
                    resendOTP={e => resendCode(userInfo)}
                    onBack={e => setUserInfo(null)}
                    errMsg={otpError}
                />}
            </div>
        </div>
    )
}




function SignUp(props) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const linkId = Utils.getValueFromSearchParam(location.search, "link");
    const [show, setShow] = useState(linkId ? false : true);


    function postSignUpProcess() {
        history.push({
            pathname: Consts.BASE_URL || '/',
            state: {
                from: history.location.pathname
            }
        });
    }

    function goToLogin() {
        history.push({
            pathname: `${Consts.BASE_URL}/sign-in`,
            state: { ...history.location?.state },
        });
        handleClose();
    }

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout(history, dispatch);
        }
    }, []);
    const handleClose = () => setShow(false)
    return (
        <div className={classes.mainContainer}>
            <HelmetProvider
                title={"Bookalooza- Write, Publish and Earn through Books"}
                description={
                    "Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book."
                }
                schema={
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Bookalooza",
                        "url": "https://www.bookalooza.com/sign-up",
                        "logo": "https://www.bookalooza.com/logo.png",
                        "sameAs": [
                            "https://www.facebook.com/bookalooza",
                            "https://www.instagram.com/bookalooza/",
                            "https://www.youtube.com/@Bookalooza",
                            "https://www.linkedin.com/company/bookalooza/",
                            "https://x.com/bookalooza"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Customer Service",
                            "telephone": "+91-8799721408",
                            "email": "support@bookalooza.com"
                        }
                    }}
            />
            {
                show && <ModalDialog
                    title={<b>Have You Registered Through School?</b>}
                    onClose={handleClose}
                    content={<div className={classes.disclaimer}>
                        <p>Log in using your School ID and password. The amount you paid at School will be adjusted when you order your book.</p>
                        <p><b>Note:</b> Please do not create a new account if you have already registered through the school. The charges will be different, and we cannot give you a special discount.</p>
                    </div>}
                    footer={<>
                        <SecondaryButton onClick={goToLogin}>Login</SecondaryButton>
                        <PrimaryButton onClick={handleClose}>Skip & continue</PrimaryButton>
                    </>}
                />
            }
            <div className={classes.rightMainBox}>
                <img src={signUpbackgroundImage} className={classes.backgroundImage} />
                <img src={signUpBackgroundStars} className={classes.backgroundStars} />
            </div>
            <SignupForm postSignUpProcess={postSignUpProcess} goToLogin={goToLogin} />
        </div>
    );
}

export default SignUp;