import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useFabricCanvas } from "../../modules/Editor/FabricCanvas";
import { addTextboxObject } from "../../modules/Editor/objectUtils";
import classes from "./AIData.module.css";
import { useSelector } from "react-redux";

const AIData = ({ text, onClick, addIcon = true }) => {
  const canvas = useFabricCanvas();
  const defaultColor = useSelector(s => s.foregroundColors.defaultColor);

  return (
    <div className={classes.main} onClick={onClick}>
      <div className={classes.text}>{text}</div>
      {
        addIcon &&
        <FontAwesomeIcon
          onClick={(e) => {
            e.stopPropagation()
            addTextboxObject(canvas, {
              text: text,
              fill: defaultColor
            })
          }}
          className={classes.icon}
          size="lg"
          icon={faCirclePlus}
        />
      }
    </div>
  )
}

export default AIData;