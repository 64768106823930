import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { logout, userProfile } from '../../modules/Users/UsersActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { deleteNotification } from '../../modules/Books/BooksActions';
import { useMobileScreen } from '../../custom-hooks/MobileScreen';
import { AddWalletBalance } from '../PaymentForms/Payment';
import { getPaymentConfig } from '../../modules/AddCartScreen/AddCartActions';
import classes from './Header.module.css';
import Utils from '../../Utils';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import DropDownItem from '../DropDownMenu/DropDownItem';
import logoImage from "../../assets/bookalooza-logo.png";
import cartImage from '../../assets/cart-icon.svg'
import keyIcon from "../../assets/change-password.svg";
import personIcon from "../../assets/person-outline.svg";
import logoutIcon from '../../assets/logout.svg';
import bellIcon from '../../assets/bell-icon.svg';
import NumberIcon from '../NumberIcon/NumberIcon';
import reject from '../../assets/dashboardImage/notification-icon-rejected.svg';
import review from '../../assets/dashboardImage/notification-icon-review.svg';
import liked from '../../assets/dashboardImage/notification-icon-liked.svg';
import approved from '../../assets/dashboardImage/notification-icon-approved.svg';
import ordered from '../../assets/dashboardImage/notification-icon-ordered.svg'
import LinkButton from '../Buttons/LinkButton';
import Divider from '../Divider/Divider';
import Consts from '../../Consts';
import SideBarMenu from './SideBarMenu';
import NavBar from './NavBar';
import ModalDialog from '../Dialog/Dialog';
import SecondaryButton from '../Buttons/SecondaryButton';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

const cardIcon = {
    review: review,
    rejected: reject,
    approved: approved,
    liked: liked,
    ordered: ordered
}

const url = Consts.BASE_URL;

const menuItems = [
    {
        title: "ABOUT US",
        url: `/about-us`,
    },
    {
        title: "CREATE",
        url: `/newbook`,
    },
    {
        title: "BOOKSTORE",
        url: `/store`,
    },
    {
        title: "MY LIBRARY",
        url: `/user/library`,
    },
    // {
    //     title: "MEDIA AND PR",
    //     url: `/newsroom/media`,
    // },
    {
        title: "BLOGS",
        url: '/blogs'
    },
    {
        title: "WORKSHOPS",
        url: "/workshops/",
        subMenu: [
            {
                title: "SCHOOL",
                url: '/workshops/school'
            },

            {
                title: "STUDENT",
                url: '/workshops/students',

            }
        ]


    },
    {
        title: "PODCAST",
        url: "/podcasts",
    },
    {
        title: "CONTACT US",
        url: '/contact-us'
    },
]

function Header() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const isMobile = useMobileScreen();
    const currentTitle = useSelector((state) => state.books.currentTitle);
    const book = useSelector((state) => state.books.book);
    const user = useSelector((state) => state.user.user);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(Utils.isUserLoggedIn());
    const cartItemsCount = useSelector((state) => state.addCart.cartItems.length);
    const notifications = useSelector((state) => state.user.notifications);
    const [showPaymentDialogue, setShowPaymentDialogue] = useState(false)
    const [showDialogue, setShowDialogue] = useState(false);
    const [config, setConfig] = useState();
    const newNotifications = [...notifications]
    newNotifications?.sort((a, b) => parseInt(b.time) - parseInt(a.time));

    const isSchoolChildren = Boolean(config && user?.gatewayPaymentStatus !== "success");

    useEffect(() => {
        if (isUserLoggedIn) {
            getPaymentConfig().then(cnf => {
                userProfile(dispatch).then((res) => {
                    if (res.data.linkId && res.data.gatewayPaymentStatus !== "success") {
                        setShowDialogue(true);
                        setConfig(cnf);
                    }
                })
            })
        }
    }, [isUserLoggedIn])

    useEffect(() => {
        const isLoggedIn = Utils.isUserLoggedIn();
        setIsUserLoggedIn(isLoggedIn);
        if (isLoggedIn && isSchoolChildren) {
            if (location.pathname !== "/") {
                history.replace(url);
                setShowPaymentDialogue(true);
            }
        }
    }, [location, user, isSchoolChildren]);

    const isDesigning = Boolean(location.pathname.match(/.*\/((newbook)|edit\/).*/) && book);

    return (
        <>
            {showDialogue &&
                <ModalDialog
                    title={"Hey Star Author!"}
                    content={<div>
                        Your story is waiting to be told. Make a payment now and your money will be loaded in the wallet. Once you're ready to publish your book, this amount will be adjusted at checkout. So, write on worry-free!
                    </div>}
                    footer={
                        <div>
                            <SecondaryButton onClick={() => {
                                setShowDialogue(false);
                                setShowPaymentDialogue(true);
                            }}>Ok</SecondaryButton>
                        </div>
                    }
                />
            }
            {showPaymentDialogue && <AddWalletBalance config={config} onClose={() => setShowPaymentDialogue(false)} userData={user} />}
            <nav className={classes.main}>
                <div className={classes.logoContainer}>
                    {
                        (!isMobile || isDesigning) && <img src={logoImage} className={classes.logoImageDesigner} onClick={() => {
                            history.push(url);
                            setShowPaymentDialogue(true);
                        }} />
                    }
                    {!isDesigning && (
                        <div className={classes.sidebarMenu}>
                            <SideBarMenu menuItems={menuItems} />
                        </div>
                    )}
                    {
                        isDesigning &&
                        <span className={classes.createBook} style={{ fontSize: '1.25rem' }}>Create your book</span>
                    }
                    {
                        !isDesigning &&
                        <ul className={classes.navMenu}>
                            <NavBar menuItems={menuItems} depth={1} />
                        </ul>
                    }
                </div>
                {
                    isDesigning && <div className={classes.bookTitle}>{currentTitle || book?.title || "Untitled"} </div>
                }
                {isUserLoggedIn && !location.pathname.includes("sign-up") ?
                    !(isDesigning && isMobile) && <div className={classes.logoutContainer}>
                        <div className={classes.cartContainer} onClick={() => history.push(`${url}/cart`)} >
                            {cartItemsCount !== 0 &&
                                <div className={classes.cartCount}>
                                    <NumberIcon number={cartItemsCount} fill={'var(--selection-dark)'} />
                                </div>
                            }
                            <img src={cartImage} className={classes.cartIcon} alt="cart-icon" />
                        </div>
                        <div className={classes.notificationsContainer}>
                            <DropDownMenu
                                childrenClass={classes.dropDownchildrenClass}
                                imageIcon={bellIcon}
                                count={notifications.length}
                                alert={alert}
                                style={{ width: "1.5rem", height: "1.5rem" }}
                                fill="white"
                                color="black"
                            >
                                {
                                    Boolean(notifications.length) &&
                                    <div className={classes.notificationWrapper}>
                                        <div className={classes.notificationHeading}>
                                            <h1>Notification</h1>
                                            <div className={classes.linkButtonsWrapper}>
                                                <LinkButton onClick={() => history.push(`${url}/user/notifications`)} style={{ color: '#707070', fontSize: '0.875rem' }}>See all</LinkButton>
                                                <LinkButton style={{ color: '#707070', fontSize: '0.875rem' }}>Close</LinkButton>
                                            </div>
                                        </div>
                                        <Divider style={{ width: '100%' }} />
                                        <div className={classes.notifications}>
                                            {newNotifications.map((notification) => {
                                                const bookTitle = notification?.bookDetails?.[0]?.title;
                                                let title = '';
                                                let description = '';
                                                let status = notification?.action;
                                                let icon;
                                                if (status === "ORDERED") {
                                                    icon = cardIcon['ordered'];
                                                    title = "Somebody purchased your book !";
                                                    description = <>Your book <span className={classes.boldText}>{bookTitle || "Untitled"}</span> has been ordered by someone.</>
                                                } else if (status === "LIKED") {
                                                    icon = cardIcon['liked'];
                                                    title = "Somebody liked your book !";
                                                    description = <>Someone liked your book with the title <span className={classes.boldText}>{bookTitle || "Untitled"}</span>.</>;
                                                } else if (status === "REJECTED") {
                                                    icon = cardIcon['rejected'];
                                                    title = status;
                                                    const remarks = notification?.bookDetails[0]?.remarks;
                                                    let remarkText = '';
                                                    if (remarks) {
                                                        remarkText = remarks[(remarks.length - 1)]?.text;
                                                    }
                                                    description = <>Your book with title <span className={classes.boldText}>{bookTitle || "Untitled"}</span> has been rejected due to <span className={classes.boldText}>{remarkText}</span>.  Please edit your book and submit again.</>
                                                } else if (status === "APPROVED") {
                                                    icon = cardIcon['approved'];
                                                    title = status;
                                                    description = <div className={classes.approvedNotificationWrapper}><span>Your book with title <span className={classes.boldText}>{bookTitle || "Untitled"}</span> has been approved and successfully published.</span> <span>Order your copy now! and earn 10% of MRP as Author Royalty.</span> <span>View your E-certificate and Social media poster <Link to='/user/my-achievements'>here</Link>.</span></div>
                                                }
                                                const timeSince = Utils.getTimeSince(notification.time);
                                                return <div className={classes.notification} key={notification.id} onClick={e => e.stopPropagation()}>
                                                    <div><img src={icon} alt="icon" /></div>
                                                    <div className={classes.NotificationWrapper}>
                                                        <div className={classes.NotificationContentWrapper}>
                                                            <h1 className={classes.notificationTitle}>{title}</h1>
                                                            <p className={classes.notificationDescription}>{description}</p>
                                                        </div>
                                                        <p className={classes.timeZone}>{timeSince}</p>
                                                    </div>
                                                    <div className='cursor-pointer' onClick={(e) => {
                                                        deleteNotification(dispatch, notification?.id);
                                                        e.stopPropagation();
                                                    }}><FontAwesomeIcon icon={faXmark} /></div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                }
                            </DropDownMenu>
                        </div>
                        <div className={classes.userDetailsWrapper}>
                            <DropDownMenu isOnMouseEnter title={<div className={classes.userDetails}><Avatar className={Utils.getClasses(classes.userImage, user?.photoURL ? classes.userPhoto : null)}><img src={personIcon} alt='' /></Avatar> {user?.fullName || "user name"}</div>}>
                                <DropDownItem title="My Account" onClick={() => history.push(`${url}/user/dashboard`)} imageIcon={personIcon} />
                                <DropDownItem title="Change Password" onClick={() => history.push(`${url}/change-password`)} imageIcon={keyIcon} />
                                <DropDownItem title="Logout" onClick={e => logout(history, dispatch)} imageIcon={logoutIcon} />
                            </DropDownMenu>
                        </div>
                    </div> :
                    !location.pathname.includes("sign-in") &&
                    <div className={classes.navMenuItem}>
                        <span className={classes.item} onClick={() => history.push(`${url}/sign-in`)}>Login</span>
                    </div>

                }
            </nav>
        </>
    );
}


function NavItem({ item, isActive, onClick, isCreate }) {
    return (
        <li
            className={Utils.getClasses(classes.navMenuItem, isActive && classes.navMenuItemActive, isCreate && classes.mobileScreenHidden)}
            onClick={onClick}
        >{item.title}</li>
    );
}

export default Header;

