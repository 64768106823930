import React, { useState } from 'react';
import classes from './AboutAuthor.module.css'
import Container from '../components/Container'
import { useFabricCanvas } from '../../FabricCanvas';
import { useBookContext } from '../../BookContext';
import { useEffect } from 'react';
import { fetchAllAboutAuthorTemplates } from './AboutAuthorAction';
import { useDispatch, useSelector } from 'react-redux';

function AboutAuthor({ visible }) {
    const bookCtx = useBookContext();
    const canvas = useFabricCanvas();
    const dispatch = useDispatch();
    const allTemplates = useSelector((state) => state.aboutAuthor.allAboutAuthorTemplates)

    useEffect(() => {
        fetchAllAboutAuthorTemplates(dispatch);
    }, []);

    return (
        <Container visible={visible} title={"About Author"}>
            <div className="scroll-vertical scroll-style" style={{ height: '90%', marginTop: '0.6rem' }}>
                {allTemplates.length ?
                    allTemplates.map(({ id, text }) => {
                        return <div key={id} className={classes.authorText} onClick={e => {
                            let contents = canvas.getLayerById("contents"),
                                aboutAuthor;
                            if (contents) {
                                aboutAuthor = contents.getObjects().find((e) => e.id === "aboutAuthor");
                            }
                            if (aboutAuthor) {
                                aboutAuthor.set({ text });
                                canvas.fire('object:modified', { target: aboutAuthor });
                                canvas.renderAll();
                                bookCtx.setAboutAuthor(text);
                            }
                        }}>{text}</div>
                    }) : < div className={classes.authorText}>No template available.</div>
                }
            </div>
        </Container>
    )
}

export default AboutAuthor