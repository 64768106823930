import React from "react";
import { useHistory } from "react-router";
import classes from "./SignIn.module.css";
import Consts from "../../Consts";
import SignInForm from "./SignInForm";
import HelmetProvider from "../Helmet/Helmet";

function SignIn(props) {
	const history = useHistory();

	function postSignInProcess() {
		localStorage.setItem("isLoggedIn", "true");
		history.push(history.location?.state?.from || Consts.BASE_URL || '/');

	}

	function createHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/sign-up`,
			state: { ...history.location?.state },
		});
	}

	function forgotPasswordHandler() {
		history.push({
			pathname: `${Consts.BASE_URL}/forgot-password`,
			state: { ...history.location?.state },
		});
	}

	return (
		<div className={classes.mainContainer}>
			<HelmetProvider
				title={"Bookalooza- Write, Publish and Earn through Books"}
				description={
					"Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book."
				}
				schema={
					{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "Bookalooza",
						"url": "https://www.bookalooza.}/sign-in",
						"logo": "https://www.bookalooza.com/logo.png",
						"sameAs": [
							"https://www.facebook.com/bookalooza",
							"https://www.instagram.com/bookalooza/",
							"https://www.youtube.com/@Bookalooza",
							"https://www.linkedin.com/company/bookalooza/",
							"https://x.com/bookalooza"
						],
						"contactPoint": {
							"@type": "ContactPoint",
							"contactType": "Customer Service",
							"telephone": "+91-8799721408",
							"email": "support@bookalooza.com"
						}
					}}
			/>
			<SignInForm
				postSignInProcess={postSignInProcess}
				createHandler={createHandler}
				forgotPasswordHandler={forgotPasswordHandler}
				fetchedUserDetails={props.userDetails || {}}
			/>
		</div>
	);
}

export default SignIn;
