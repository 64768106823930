import React, { Suspense } from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router"
import { useDispatch, useSelector } from 'react-redux';
import classes from './NewBook.module.css';
import ThemeItem from '../../../components/ThemeItem/ThemeItem';
import { booksActions } from '../BooksSlice';
import BackButton from '../../../components/Buttons/BackButton';
import Utils from '../../../Utils';
import { fetchAllThemes } from './ThemeActions';
import { fetchStoryLines } from '../../Editor/AIHelp/AIHelpAction';
import { Helmet } from 'react-helmet';
import HelmetProvider from '../../../components/Helmet/Helmet';

const Editor = React.lazy(() => import('../../Editor/Editor'));

export default function NewBook() {
    const [selectedTheme, setSelectedTheme] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(booksActions.fetchBook({}));
        fetchAllThemes(dispatch);
    }, []);

    const allThemes = useSelector(state => state.themes.allThemes);
    const trendingThemes = useSelector(state => state.themes.trendingThemes);
    const popularThemes = useSelector(state => state.themes.popularThemes);

    const book = useSelector(state => state.books.book);
    useEffect(() => {
        if (allThemes) {
            setSelectedTheme(allThemes[0]);
        }
    }, [allThemes]);

    const handleThemeClick = (e) => {
        const book = {
            theme: e.name
        };
        history.push({ search: new URLSearchParams(book).toString() })
        dispatch(booksActions.fetchBook({ book }));
    }

    return <>
        <HelmetProvider
            title={"Create Books - Bookalooza"}
            description={"Sign up to create your account and write your book free of cost."}
            schema={{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Create a New Book on Bookalooza",
                "url": "https://www.bookalooza.com/newbook",
                "description": "Start creating your new book using Bookalooza’s AI-based platform. Write, edit, and publish your book easily."
            }}
        />
        {
            book ? <Suspense fallback={<>Loading...</>}>
                <Editor book={book} />
            </Suspense> :
                <div className={classes.mainContainer}>
                    <div className={classes.paddedContainer}>
                        <BackButton />
                        <div className={classes.heading}>
                            <h1 className={classes.headerText}>Choose a theme for your book</h1>
                        </div>
                        <div className={classes.innerContainer}>
                            <div className={Utils.getClasses(classes.trendingThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>Trending themes</h3>
                                <Themes
                                    scrollBar
                                    themeItems={trendingThemes}
                                    onSelectionChange={handleThemeClick}
                                />
                            </div>
                            <div className={Utils.getClasses(classes.mostPopularThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>Most popular themes</h3>
                                <Themes
                                    scrollBar
                                    // onSelectionChange={setSelectedTheme}
                                    themeItems={popularThemes}
                                    onSelectionChange={handleThemeClick}
                                />
                            </div>
                            <div className={Utils.getClasses(classes.allThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>All themes</h3>
                                <div className={classes.container}>
                                    <div className={classes.allThemesContainer}>
                                        <Themes
                                            scrollBar
                                            // onSelectionChange={setSelectedTheme}
                                            themeItems={allThemes}
                                            themeItemStyle={{ background: 'rgba(246, 246, 246, 1)' }}
                                            onSelectionChange={handleThemeClick}
                                        />
                                    </div>
                                    {/* <div className={classes.nextButton}>
                                        <PrimaryButton
                                            className={classes.next}
                                            onClick={e => {
                                                const book = {
                                                    theme: selectedTheme.name
                                                };
                                                dispatch(booksActions.fetchBook({ book }));
                                            }}
                                            style={{ width: "10rem", margin: 0 }}
                                        >
                                            Next
                                            <span style={{ marginLeft: "0.875rem" }} className="fa fa-arrow-right"></span>
                                        </PrimaryButton>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </>;
};

const Themes = ({ themeItems, selectedTheme, onSelectionChange, themeItemStyle, scrollBar, onDoubleClick }) => {
    const storyLines = useSelector((s) => s.aiHelp.storyLines);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!storyLines?.length) {
            fetchStoryLines(dispatch);
        }
    }, [storyLines]);

    const aiThemes = new Set(storyLines?.map(i => i.theme.toLowerCase()));
    return (
        <div className={Utils.getClasses(scrollBar ? "scroll-vertical scroll-style" : classes.themesContainerWrapper, scrollBar && classes.scrollBarForThemes)}>
            <div className={classes.themes}>
                {
                    themeItems.map((themeItem) =>
                        <ThemeItem
                            themeImage={themeItem?.displayImage}
                            key={themeItem?.name}
                            themeName={themeItem?.name}
                            onDoubleClick={() => {
                                onSelectionChange(themeItem);
                                onDoubleClick();
                            }}
                            onClick={() => onSelectionChange(themeItem)}
                            selected={themeItem === selectedTheme}
                            style={{ ...themeItemStyle }}
                            ai={aiThemes.has(themeItem.name.toLowerCase())}
                        />
                    )
                }
            </div>
        </div>)
};
